<template>
  <main class="models-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card :title="getTitle">
              <div class="card-body" v-if="!(is_3d || is_mask)">
                <mega-drop-zone
                  @change="renderFile"
                  :type="is_gif ? ['image/gif'] : ['image/jpeg', 'image/png']"
                >
                  <mega-image ratio="1x1" :src="''" v-if="!preview" />
                  <img
                    class="w-100"
                    alt
                    style="transition: .1s"
                    :src="preview"
                    v-if="preview"
                  />
                </mega-drop-zone>
              </div>

              <div class="card-body">
                <mega-input @input="save_disabled = false" :label="$t('model_name')" v-model="model.name" />
              </div>

              <ul class="menu-list">
                <li class="menu-item">
                  <mega-switch
                    class="w-100 mb-0 bg-success"
                    :label="$t('model_show')"
                    @click="toggleActive"
                    :checked="model['is_active']"
                  />
                </li>
              </ul>
            </mega-card>

            <mega-card
              :title="$t('model_modifications')"
              :menu="[{text: model.modifications ? model.modifications.length : 0}]"
              v-if="is_3d || is_mask"
            >
              <div class="card-body">
                <div class="row">
                  <div class="xs:mb-3 xs:col-12 sm-up:col-9 md:col-10 lg-up:col-8">
                    <mega-select v-model="current_modif" :options="modif_list" @select="openModif" />
                  </div>
                  <div class="col sm-up:pl-0">
                    <mega-button
                      class="btn w-100 btn-primary p-0"
                      :loading="loading"
                      @click="addModification"
                    >
                      <i class="i-plus"></i>
                    </mega-button>
                  </div>
                </div>
              </div>

              <div class="card-body pt-0">
                <mega-drop-zone
                  @change="renderFile"
                  :type="['image/jpeg', 'image/png', 'image/gif']"
                >
                  <mega-image ratio="1x1" :src="''" v-if="!preview" />
                  <img
                    class="w-100"
                    alt
                    style="transition: .1s"
                    :src="preview"
                    v-if="preview"
                    :class="{'opacity-0': rendering}"
                  />
                </mega-drop-zone>
              </div>

              <div class="card-body">
                <mega-switch
                  class="w-100 bg-success"
                  :label="$t('model_mod_show')"
                  :disabled="!(mask['ios'] || mask['android'] || mask['web'] || (modification['url_sfb'] && modification['url_archive']))"
                  @click="changeModif('is_active', !modification['is_active'])"
                  :checked="modification['is_active']"
                />
                <mega-switch
                  v-if="is_3d"
                  class="w-100 bg-success"
                  label="1:1"
                  @click="changeModif('scaled', !modification['scaled'])"
                  :checked="modification['scaled']"
                />

                <mega-input @input="save_disabled = false" :label="$t('model_mod_name')" v-model="modification.name" />
              </div>
            </mega-card>

            <mega-card>
              <div class="card-body">
                <mega-button
                  class="btn w-100 btn-success"
                  :loading="loading"
                  @click="submit"
                  :disabled="save_disabled"
                >{{ $t('model_save') }}</mega-button>
              </div>
            </mega-card>

            <qr-code :uri="qrParams"/>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card class="mb-0" v-if="loaded && !modification['url_sfb'] && is_3d">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_android_warn') }}</mega-alert>
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !modification['url_archive'] && is_3d">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_ios_warn') }}</mega-alert>
            </mega-card>

            <mega-card
              class="mb-0"
              v-if="loaded && !(mask['android'] && mask['android']['sfb']) && is_mask"
            >
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_android_sfb_mask_warn') }}</mega-alert>
            </mega-card>

            <mega-card
              class="mb-0"
              v-if="loaded && !(mask['android'] && mask['android']['texture']) && is_mask"
            >
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_android_texture_mask_warn') }}</mega-alert>
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !(mask['ios'] && mask['ios']['zip']) && is_mask">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_ios_mask_warn') }}</mega-alert>
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !(mask['ios'] && mask['ios']['texture']) && is_mask">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_ios_tex_warn') }}</mega-alert>
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !mask['web'] && is_mask">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
              >{{ $t('model_web_mask_warn') }}</mega-alert>
            </mega-card>

            <mega-card :title="$t('model_vis')">
              <div class="card-body">
                <ul class="menu-list">
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_ios')"
                      :disabled="is_visible_ios"
                      @click="changeModif('is_ios', !modification['is_ios'])"
                      :checked="modification['is_ios']"
                    />
                  </li>
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_android')"
                      :disabled="is_visible_android"
                      @click="changeModif('is_android', !modification['is_android'])"
                      :checked="modification['is_android']"
                    />
                  </li>
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_web')"
                      :disabled="is_visible_web"
                      @click="changeModif('is_web', !modification['is_web'])"
                      :checked="modification['is_web']"
                    />
                  </li>
                </ul>
              </div>
            </mega-card>

            <mega-card :title="$t('model_archives')" v-if="is_mask || is_3d">
              <div class="card-body">
                <ul class="menu-list">
                  <model-file
                    type="apple_3d"
                    placeholder="iOS"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  />
                  <model-file
                    type="android_3d"
                    placeholder="Android"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  />

                  <model-file
                    type="web_3d"
                    placeholder="Web"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  />

                  <model-file
                    type="usdz_web_3d"
                    placeholder="Web USDZ, iOS"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  />

                  <!-- <model-file
                    type="gltf_web_3d"
                    placeholder="Web gLTF, Android"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  /> -->

                  <model-file
                    type="ios_mask_legacy"
                    placeholder="iOS legacy mask"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                  <model-file
                    type="ios_mask_legacy_tex"
                    placeholder="iOS iPhone 7, 8 texture"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />

                  <model-file
                    type="apple_mask_tex"
                    placeholder="iOS Texture"
                    :modif="modification"
                    @drop="getModel"
                    v-if="is_3d"
                  />

                  <model-file
                    type="apple_mask_zip"
                    placeholder="iOS Zip"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                  <model-file
                    type="apple_mask_tex"
                    placeholder="iOS Texture"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                  <model-file
                    type="android_sfb_mask"
                    placeholder="Android SFB"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                  <model-file
                    type="android_tex_mask"
                    placeholder="Android Texture"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                  <model-file
                    type="web_mask"
                    placeholder="Web"
                    :modif="modification"
                    :mask="mask"
                    @drop="getModel"
                    v-if="is_mask"
                  />
                </ul>
              </div>
            </mega-card>

            <mega-card :title="$t('model_audio')" v-if="is_3d">
              <div class="card-body">
                <ul class="menu-list">
                  <model-file
                    type="music"
                    accept=".mp3"
                    :placeholder="$t('model_audio_file')"
                    :modif="modification"
                    @drop="getModel"
                  />
                  <li class="menu-item md-down:d-block" v-if="modification['audio']">
                    <span class="form-label mr-auto md-down:mb-3">
                      <i class="mr-3" :class="playModes[play_mode].icon"></i>
                      {{ $t('audio_mode') }}
                    </span>

                    <div class="md-down:d-flex md-down:w-100">
                      <mega-button
                        class="btn-sm md-down:p-0 md-down:w-100"
                        v-for="(mode, i) in playModes"
                        :key="i"
                        :class="{'btn-primary': play_mode === i}"
                        @click="changePlayMode(i)"
                      >
                        <span>{{mode.name}}</span>
                      </mega-button>
                    </div>
                  </li>
                </ul>
              </div>
            </mega-card>

            <mega-card :title="$t('gif_src')" v-if="is_gif">
              <div class="card-body">
                <mega-image
                  style="background-size: contain;background-repeat: no-repeat;"
                  ratio="16x9"
                  :src="modification.url"
                />
              </div>
            </mega-card>

            <mega-card :title="$t('video_src')" v-if="is_video">
              <div class="card-body d-flex flex-column" v-if="modification.video">
                <div class="d-flex">
                  <mega-btn-upload
                    class="btn w-100 mb-3 btn-primary align-self-end"
                    :loading="loading"
                    @change="loadVideo"
                    :type="['video/mp4']"
                  >{{ $t('video_new') }}</mega-btn-upload>
                </div>
                <mega-video ratio="16x9" :src="modification.video ? modification.video.url : ''" />
              </div>
              <div class="card-body" v-else>
                <mega-drop-zone
                  class="content-center mt-3 p-5"
                  @change="loadVideo"
                  :type="['video/mp4']"
                >
                  <mega-video ratio="16x9" :src="modification.video ? modification.video.url : ''" />
                </mega-drop-zone>
              </div>
            </mega-card>

            <mega-card :title="$t('model_rec_imgs')" :menu="[{icon: 'i-plus', click: addImage, style: 'font-size:24px'}]">
              <div class="card-body" v-if="serverRefImages.length">

                <ul class="menu-list">
                  <li
                    class="d-flex align-items-center justify-content-between p-3"
                    v-for="(img, i) in serverRefImages"
                    :key="i"
                  >
                    <div class="d-flex flex-column" :style="{ fontSize: '15px', paddingRight: '10px' }">
                      <span class="">ID: <b>{{ img.idt_ref_image }}</b></span>
                      <span class="mr-auto">{{ $t('ref_info_name') }}: <b>{{ img.name }}</b></span>
                      <span class="mr-auto">{{ $t('ref_info_date') }}: <b>{{ refDate(img.a_time) }}</b></span>
                      <span class="mr-auto">{{ $t('ref_pic_width') }}: <b>{{ img.width }}</b></span>
                      <span class="mr-auto">{{ $t('ref_global_loc') }}: <b>{{ img.is_global ? $t('ref_info_on') : $t('ref_info_off') }}</b></span>
                      <span class="mr-auto">{{ $t('ref_sticky') }}: <b>{{ img.sticky ? $t('ref_info_on') : $t('ref_info_off') }}</b></span>
                      <span v-if="img.ref_strings" class="mr-auto">{{ $t('ref_info_key_words') }}: <b>{{ img.ref_strings.join(',') }}</b></span>
                      <Dropdown class="cursor-pointer" :label="$t('ref_ocr')" v-if="img.ocr_strings" :key_words="img.ocr_strings" />
                    </div>

                    <div style="width: 140px;flex:0 0 140px">
                      <mega-image class="cursor-pointer" @click="openPreview(img.url)" ratio="1x1" small :src="img.url" />
                      <mega-button
                        class="btn w-100 btn-primary p-0 mt-2"
                        @click="editImage(img['idt_ref_image'])">{{ $t('ref_edit_btn') }}</mega-button>
                    </div>
                  </li>
                </ul>
              </div>
            </mega-card>
          </div>
        </div>
      </div>
    </section>

    <new-image ref="new-image" :id="model_id" :mod_id="modification_id" @close="getModel" />
    <edit-image ref="edit-image" @close="getModel" />
    <ImagePreview ref="preview" ratio="1x1"/>
  </main>
</template>

<script>
import GroupSelect from "../../components/group-select";

import ModelFile from "./components/model-file";

import QrCodeWithLogo from "qr-code-with-logo";
import QrCode from "../../components/qr-code"

import ImagePreview from '../../components/image-preview'

import moment from 'moment'

import Dropdown from '../../../components/Dropdown'

import NewImage from "../../components/new_image";
import EditImage from "../../components/edit_image";

export default {
  name: "ModelPage",
  props: ["model_id", "modification_id"],
  data() {
    return {
      is_3d: false,
      is_gif: false,
      is_video: false,
      is_image: false,
      is_mask: false,
      save_disabled: true,
      model: {},
      modification: {},
      mask: {},
      serverRefImages: [],
      preview: undefined,
      loading: true,
      loaded: false,
      qr_image: undefined,
      qr_code: undefined,
      current_modif: undefined,
      playModes: {
        once: { name: this.$t("audio_mode_once"), icon: "i-play" },
        loop: { name: this.$t("audio_mode_loop"), icon: "i-rotate-cw" },
        on_tap: { name: this.$t("audio_mode_tap"), icon: "i-thumbs-touch" },
        disabled: { name: this.$t("audio_mode_dis"), icon: "i-close" }
      }
    };
  },
  computed: {
    qrParams() {
      return `type=${this.is_mask ? 'mask' : 'mod'}&id1=${this.model_id}&id2=${this.modification_id}`;
    },
    modif_list() {
      let arr = [];
      let modifications = this.model.modifications || [];

      modifications.map((item, i) => {
        arr.push({ value: i, name: item.name });
      });

      return arr;
    },
    play_mode: {
      get() {
        return this.modification["audio"]
          ? this.modification["audio"]["play_mode"]
          : undefined;
      },
      set(v) {
        this.modification["audio"]["play_mode"] = v;
      }
    },
    is_visible_ios() {
      return !(
        (this.mask['ios'] && (this.mask["ios"]["zip"] || this.mask["ios"]["texture"])) ||
        this.modification["url_archive"] ||
        this.modification["url"] ||
				this.modification["video"] ||
				this.modification['image']
      );
    },
    is_visible_android() {
      return !(
        (this.mask["android"] &&
          (this.mask["android"]["sfb"] || this.mask["android"]["texture"])) ||
        this.modification["url_sfb"] ||
        this.modification["url"] ||
				this.modification["video"] ||
				this.modification['image']
      );
    },
    is_visible_web() {
      return !(
        (this.mask && this.mask.web) ||
        this.modification["url_archive"] ||
        this.modification["url_sfb"] ||
        this.modification["url"] ||
				this.modification["video"] ||
        this.modification['image'] ||
        (this.modification["three_d"] && this.modification["three_d"]["web_gltf"])||
        (this.modification["three_d"] && this.modification["three_d"]["web_usdz"])

      );
    },
    getTitle() {
      if (this.is_3d)
        return this.$t('model_title');
      else if (this.is_gif)
        return this.$t('gif_title');
      else if (this.is_video)
        return this.$t('video_title');
      else if (this.is_image)
        return this.$t('img_title');
      else if (this.is_mask)
        return this.$t('mask_title');
    }
  },
  mounted() {
    this.getModel();
    this.buildQrCanvas();
  },
  methods: {
    buildQrCanvas() {
      this.qr_code = document.createElement("canvas");
      this.qr_code.width = 1000;
      this.qr_code.height = 1000;
      this.qr_code.style.top = "-9999999px";
      this.qr_code.style.left = "-9999999px";
      this.qr_code.style.position = "absolute";
      this.qr_code.style.pointerEvents = "none";
      this.qr_code.style.visibility = "hidden";
      this.qr_code.style.imageRendering = "pixelated";
      this.qr_code.style.imageRendering = "crisp-edges";

      document.body.appendChild(this.qr_code);
    },

    openPreview(url) {
      this.$refs.preview.open(url);
    },

    addImage() {
      if (window && window.isMobileOrTablet())
        this.$router.push(
          `/ref_image/new/${this.model_id}/${this.modification_id}`
        );
      else this.$refs["new-image"].open();
    },

    editImage(id) {
      if (window && window.isMobileOrTablet())
        this.$router.push("/ref_image/" + id);
      else this.$refs["edit-image"].open(id);
    },

    toggleActive() {
      let active = !this.model["is_active"];

      let data = {
        idt_model: this.model["idt_model"],
        is_active: active
      };

      this.$api.v2
        .put("/user/model", data)
        .then(response => {
          this.model = response.data;
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    change(prop, value) {
      let data = {
        idt_model: this.model["idt_model"]
      };

      data[prop] = value;

      this.$api.v2
        .put("/user/model", data)
        .then(response => {
          this.model[prop] = response.data[prop];
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    changePlayMode(value) {
      let formData = new FormData();

      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      if (this.play_mode) formData.append("audio_play_mode", value);

      this.$api.v2
        .put("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(({ data }) => {
          this.play_mode = data.modif["audio"]["play_mode"];
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    changeModif(prop, value) {
      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append(prop, value);

      this.$api.v2
        .put("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.modification = response.data["modif"];
          this.getModel();
          this.$alert.success(this.$t("model_updated"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    openModif(i) {
      let modif = this.model.modifications[i] || [];

      if (modif.ref_images) {
        modif["ref_images"].map(ref => {
          this.serverRefImages.push(ref);
        });
      }

      if (modif) {
        this.current_modif = i;
        if (this.modification_id !== modif["idt_model_modif"])
          this.$router.push({
            params: { modification_id: modif["idt_model_modif"] }
          });

        this.$navbar.name = `${this.getTitle} #${
          modif["idt_model_modif"]
        } | ${this.model.name}`;

        if (modif["url_icon"]) {
          this.$render(modif["url_icon"]).then(src => {
            this.preview = src;
          });
				} else if (modif['image']) {
					this.$render(modif['image']['url'])
            .then(src => { this.preview = src; });
				} else this.preview = undefined;

        let nodeQrCodeOptions = {
            margin: 0,
            color: {
              dark: "#5cc8c8",
              light: "#0000"
            }
          },
          logo = {
            src: require("../../../assets/logo-sphere.svg"),
            borderRadius: 0,
            logoSize: 0.2,
            radius: 0
          };

        QrCodeWithLogo.toCanvas({
          canvas: this.qr_code,
          content: "https://arround.world/?qr=" + modif["idt_model_modif"],
          width: 500,
          nodeQrCodeOptions,
          logo
        }).then(() => {
          this.qr_image = this.qr_code.toDataURL();

          this.qr_code.removeAttribute("style");
          this.qr_code.width = 1000;
          this.qr_code.height = 1000;
          this.qr_code.style.top = "-9999999px";
          this.qr_code.style.left = "-9999999px";
          this.qr_code.style.position = "absolute";
          this.qr_code.style.pointerEvents = "none";
          this.qr_code.style.visibility = "hidden";
          this.qr_code.style.imageRendering = "pixelated";
          this.qr_code.style.imageRendering = "crisp-edges";
        });

        this.modification = modif;

        this.setMask(modif.mask);

        this.save_disabled = true;
      }
    },

    renderFile(file) {
      this.loading = true;

      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);

      if (!this.is_image) formData.append("image", file);
      else formData.append("the_image", file);

      if (this.is_gif) formData.append("gif", file);

      // if (this.is_video) formData.append("video", file);

      this.$api.v2
        .put("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.getModel();
          this.loading = false;
          this.$alert.success("Preview was updated");
          let img = new Image();
          img.src =
            response.data["modif"]["url_icon"] + "?t=" + new Date().getTime();

          setTimeout(() => {
            this.preview = img.src;
          }, 100);

        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    getModel() {
      this.$api.v2
        .get("/user/model", { params: { idt_model: this.model_id } })
        .then(response => {
          this.model = response.data;

          this.setType(this.model.idc_round_type);

          if (
            this.model.modifications.filter(
              modif => modif.idt_model_modif === Number(this.modification_id)
            ).length === 0
          ) {
            throw new Error("This modification do not exist!");
          }

          this.loaded = true;
          this.loading = false;

          this.serverRefImages = [];

          let id = this.modification_id || undefined;

          if (id) {
            let modifications = this.model.modifications || [];

            modifications.map((item, i) => {
              if (item["idt_model_modif"] === parseInt(id)) this.openModif(i);
            });
          } else this.openModif(0);
        })
        .catch(err => {
          this.$router.replace("/404");
        });
    },

    submit() {
      this.loading = true;

      let data = {
        idt_model: this.model.idt_model,
        name: this.model.name
      };

      this.$api.v2
        .put("/user/model", data)
        .then(() => {
          let formData = new FormData();
          Object.keys(this.modification).map(prop => {
            if (
              this.modification[prop] !== undefined &&
              typeof this.modification[prop] !== "object"
            )
              formData.append(prop, this.modification[prop]);
          });

          this.$api.v2
            .put("/user/modif", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(() => {
              this.loading = false;
              this.save_disabled = true;
              this.$alert.success(this.$t('model_updated'));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    addModification() {
      this.loading = true;

      let formData = new FormData();
      formData.append("idt_model", this.model_id);
      formData.append("name", "New Modification");

      this.$api.v2
        .post("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => response.data["modif"])
        .then(modification => {
          this.loading = false;
          this.getModel();
        });
    },

    loadVideo(file) {
      this.loading = true;
      this.rendering = true;

      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append("video", file);

      this.$api.v2
        .put("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.loading = false;

          this.$alert.success("Video was updated");
          window.location.reload()
        });
    },

    setType(type) {
      switch (type) {
        case 2:
          this.is_image = true;
          break;
        case 3:
          this.is_video = true;
          break;
        case 4:
          this.is_3d = true;
          break;
        case 5:
          this.is_gif = true;
          break;
        case 10:
          this.is_mask = true;
          break;
      }
    },

    setMask(mask) {
      this.$set(this.mask, "ios", {});
      this.$set(this.mask, "web", null);
      this.$set(this.mask, "android", {});
      this.$set(this.mask["android"], "sfb", null);
      this.$set(this.mask["android"], "texture", null);
      this.$set(this.mask["ios"], "zip", null);
      this.$set(this.mask["ios"], "texture", null);

      if (mask) {
        this.$set(this.mask, "ios", mask.ios || null);
        this.$set(this.mask, "web", mask.web || null);
        this.$set(this.mask, "android", mask.android || null);
        if (this.mask["android"]) {
          this.$set(this.mask["android"], "sfb", mask.android.sfb || null);
          this.$set(
            this.mask["android"],
            "texture",
            mask.android.texture || null
          );
        }

        if (this.mask["ios"]) {
          this.$set(this.mask["ios"], "zip", mask.ios.zip || null);
          this.$set(
            this.mask["ios"],
            "texture",
            mask.ios.texture || null
          );
        }
      }
    },

    refDate(timestamp) {
      return moment.unix(timestamp).format('DD.MM.YYYY HH:mm:ss');
    }
  },
  components: {
    GroupSelect,
    ModelFile,
    EditImage,
    NewImage,
    ImagePreview,
    Dropdown,
    QrCode
  }
};
</script>
