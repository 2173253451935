<template>
  <mega-card>
    <div class="card-body" style="overflow: hidden;">
      <div class="qr-code__text pb-2">
        Вы можете отсканировать это изображение в приложении ARROUND
      </div>
      <mega-image v-if="imageCode" :src="imageCode" ratio="1x1" save-as-img />
      <div class="pt-2 qr-code__text">
        {{ fullURI }}
      </div>
    </div>
  </mega-card>
</template>

<style lang="scss">
 .qr-code {
   &__text {
     font-size: 0.8rem;
     line-height: 1.5;
   }
   &__btn {
     min-width: fit-content;
   }
 }
</style>

<script>
import QrCodeWithLogo from "qr-code-with-logo";

export default {
  data() {
    return {
      imageCode: ""
    };
  },
  props: {
    uri: {
      type: String,
      required: true,
      default: null
    }
  },
  computed: {
    fullURI() {
      const baseUri = process.env.NODE_ENV === "production"
        ? "https://arround.world/app/"
        : "https://ar.arround.world/"

      return `${baseUri}?${this.uri}`
    }
  },
  methods: {
    copy() {
      const input = this.$refs['input'].$el.querySelector('input');
      input.select()
      document.execCommand('copy')
    },
    createQr() {
      const canvas = document.createElement("canvas");
      const nodeQrCodeOptions = {
        margin: 0,
        color: {
          dark: "#5cc8c8",
          light: "#0000"
        }
      };
      const logo = {
        src: require("../../assets/logo-sphere.svg"),
        borderRadius: 0,
        logoSize: 0.2,
        radius: 0
      };

      canvas.width = 1000;
      canvas.height = 1000;
      canvas.style.top = "-9999999px";
      canvas.style.left = "-9999999px";
      canvas.style.position = "absolute";
      canvas.style.pointerEvents = "none";
      canvas.style.visibility = "hidden";
      canvas.style.imageRendering = "pixelated";
      canvas.style.imageRendering = "crisp-edges";

      document.body.appendChild(canvas);

      QrCodeWithLogo.toCanvas({
        canvas: canvas,
        content: this.fullURI,
        width: 500,
        nodeQrCodeOptions,
        logo
      }).then(() => {
        setTimeout(() => {
          this.imageCode = canvas.toDataURL();
        }, 500)
        canvas.remove();
      });
    }
  },

  mounted() {
    this.createQr();
    console.log(process.env.NODE_ENV);
  },

};
</script>
