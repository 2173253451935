var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mega-card", [
    _c(
      "div",
      { staticClass: "card-body", staticStyle: { overflow: "hidden" } },
      [
        _c("div", { staticClass: "qr-code__text pb-2" }, [
          _vm._v(
            "\n      Вы можете отсканировать это изображение в приложении ARROUND\n    "
          )
        ]),
        _vm._v(" "),
        _vm.imageCode
          ? _c("mega-image", {
              attrs: { src: _vm.imageCode, ratio: "1x1", "save-as-img": "" }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "pt-2 qr-code__text" }, [
          _vm._v("\n      " + _vm._s(_vm.fullURI) + "\n    ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }